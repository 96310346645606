import * as React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import descriptionData from "../content/description.json";
import ProductContainer from "../components/productContainer";

const About = ({
  data: {
    allAirtable: { edges },
  },
}) => {
  return (
    <Layout>
      <Seo
        title="About Shop Formal Dress"
        description="At Shop Formal dress, We present a collection of the latest and most fashionable dress styles from a variety of top-notch designers and brands. Our aim is to make your shopping experience enjoyable and less time-consuming. With our vast selections, we are your one-stop-shop for all your dress needs."
      />

      <div className="descriptions-container container px-18 max-w-screen-xl mx-auto">
        <h1 class="text-2xl mt-5 mb-5 font-bold">About Shop Formal Dress</h1>
        <p>
        Shop Formal dress saves you from the fatigue of relentless shopping and search of the most fashionable dresses from across various stores. Our platform is a one-stop destination that features the finest and latest dress styles from different sources.
        </p>

        <p>
        Whether you are searching for an elegant evening dress, formal or prom outfit, we definitely have something that will match your preference and occasion. We make an effort to accommodate various styles and tastes.
        </p>

        <p>
          Besides our wide formal and prom dress collection, Shop Formal dress also offers fashion advice and style inspiration. Our blog features interview based articles, guides from fashion experts, and keeps you updated on the latest fashion industry insights.
        </p>

        <p>
          Visit our website today, and discover a dress that makes you feel and look incredible!
        </p>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allAirtable(filter: { table: { eq: "archive_dress_party" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
  }
`;

export const Head = () => (
  <Seo
    title={descriptionData.archive_party.pageTitle}
    description={descriptionData.archive_party.pageDescription}
  />
);

export default About;
